<template>
  <div class="share-container">
    <div class="share-icon">
      <img src="../../assets/images/icon_分享.png" alt="" />
      <span class="share-button">分享</span>
    </div>
    <div class="page-box-avatar" v-if="mode === 'add'">
      <div
        class="footer-content"
        v-for="(item, key) in avatarList && avatarList.slice(0, 5)"
        :key="key"
      >
        <a-tooltip>
          <template slot="title">
            {{ item.name }}
          </template>
          <div class="content-item" :class="{ 'after-img': key !== 0 }">
            <img :src="item.headSculpture" alt="" v-if="item.headSculpture" />
            <img src="@/assets/images/icon_avatar.png" alt="" v-else />
          </div>
        </a-tooltip>
      </div>
      <div
        class="other-avatar after-img"
        v-if="avatarList && avatarList.length > 5"
      >
        <div class="other-img">
          <span
            v-if="
              avatarList && avatarList.length > 5 && avatarList.length <= 20
            "
          >{{ avatarList.length }}</span
          >
          <span v-if="avatarList && avatarList.length > 20">20+</span>
        </div>
      </div>
      <div class="share-avatar">
        <img
          src="../../assets/images/icon_添加.png"
          alt=""
          @click="openMoadlVisible"
        />
      </div>
    </div>
    <div class="page-box-avatar" v-else>
      <div
        class="footer-content"
        v-for="(item, key) in shareListShow && shareListShow.slice(0, 5)"
        :key="key"
      >
        <a-tooltip>
          <template slot="title">
            {{ item.receiverName }}
          </template>
          <div class="content-item" :class="{ 'after-img': key !== 0 }">
            <img :src="item.headSculpture" alt="" v-if="item.headSculpture" />
            <img src="@/assets/images/icon_avatar.png" alt="" v-else />
          </div>
        </a-tooltip>
      </div>

      <div
        class="other-avatar after-img"
        v-if="shareListShow && shareListShow.length > 5"
      >
        <div class="other-img">
          <span
            v-if="
              shareListShow &&
                shareListShow.length > 5 &&
                shareListShow.length <= 20
            "
          >{{ shareListShow.length }}</span
          >
          <span v-if="shareListShow && shareListShow.length > 20">20+</span>
        </div>
      </div>
    </div>
    <PersonMultile
      v-model="isMoadlVisible"
      v-show="isMoadlVisible"
      :showModal="false"
      :userList="avatarList"
      :disabledUserList="disabledUserList"
      @confirm="addPositionMultil"
    ></PersonMultile>
  </div>
</template>

<script>
import PersonMultile from '../personMultile/index.vue'
import { getShareLast } from '@/api/common'
export default {
  props: {
    mode: {
      type: String,
      default: 'add',
    },
    planType: {
      type: Number,
      default: 1,
      require: true,
    },
    id: {
      type: String,
      default: '',
      require: false,
    },
    plan: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    PersonMultile,
  },
  data() {
    return {
      isMoadlVisible: false,
      treeData: [],
      avatarList: [],
      shareList: [],
      disabledUserIdList: [],
    }
  },
  mounted() {
    if (!this.id) {
      this.getShareLastByToken()
    } else {
      this.syncAvatarList()
    }
  },
  computed: {
    disabledUserList() {
      return []
      // // 已提交的计划，只能新增分享人
      // return this.plan.id && this.plan.isSubmit == 1
      //   ? this.avatarList.filter((item) =>
      //       this.disabledUserIdList.includes(item.id),
      //     )
      //   : []
    },
    shareListShow() {
      return this.plan.shareList ?? []
    },
  },
  watch: {
    id: {
      handler(newName, oldName) {
        if (!this.id) {
          // this.getSysOrgByToken()
          this.getShareLastByToken()
        }
      },
    },
    plan() {
      this.syncAvatarList()
    },
  },
  methods: {
    syncAvatarList() {
      this.avatarList = []
      this.shareList = []
      this.plan.shareList?.forEach((item) => {
        this.avatarList.push({
          id: item.receiverId,
          headSculpture: item.headSculpture,
          name: item.receiverName,
        })
        this.shareList.push({
          receiverId: item.receiverId,
          headSculpture: item.headSculpture,
          planType: this.planType,
          receiverName: item.receiverName,
        })
        this.disabledUserIdList.push(item.receiverId)
      })
      this.$emit('addShareList', this.shareList)
    },
    async getShareLastByToken() {
      const [err, data] = await getShareLast(this.planType)
      if (!err) {
        this.avatarList = []
        this.shareList = []
        data.forEach((item) => {
          this.avatarList.push({
            id: item.receiverId,
            headSculpture: item.headSculpture,
            name: item.receiverName,
          })
          this.disabledUserIdList.push(item.receiverId)
        })

        this.avatarList.forEach((item) => {
          this.shareList.push({
            receiverId: item.id,
            headSculpture: item.headSculpture,
            planType: this.planType,
            receiverName: item.name,
          })
        })
        this.$emit('addShareList', this.shareList)
      }
    },
    addPositionMultil(e) {
      const { userList, done } = e
      this.avatarList = userList
      this.shareList = []
      if (this.avatarList && this.avatarList.length != 0) {
        this.avatarList.forEach((item) => {
          const share = {
            receiverId: item.id,
            headSculpture: item.headSculpture,
            planType: this.planType,
            receiverName: item.name,
          }
          // const oldUserId =
          //   this.shareListShow.find(
          //     (user) => user.receiverId === share.receiverId,
          //   )?.id ?? ''
          // if (oldUserId) {
          //   share.id = oldUserId
          // }
          this.shareList.push(share)
        })
      }
      console.log(this.shareList)
      done()
      this.$emit('addShareList', this.shareList)
    },
    openMoadlVisible() {
      this.isMoadlVisible = true
    },
    closeMoadlVisible() {
      this.isMoadlVisible = false
    },
  },
}
</script>
<style scoped lang="less">
.share-icon {
  display: flex;
  align-items: center;
  .share-button {
    margin-left: 9px;
  }
}
.page-box-avatar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 15px;
  // margin-left: 20px;
  .footer-content {
    .content-item {
      display: flex;
      img {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        border: solid 2px #ffffff;
      }
    }
  }
  .other-avatar {
    .other-img {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background: #f0f4f8;
      border: solid 2px #ffffff;
      text-align: center;
      line-height: 34px;
    }
  }
}
.after-img {
  margin-left: -10px;
}
.share-avatar {
  cursor: pointer;
}
</style>
